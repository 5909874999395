import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import {
  ROOM_PREFIX_DAY_EDIT,
  ROOM_PREFIX_DAY_VIEW,
  ROOM_PREFIX_WEEK_EDIT,
  ROOM_PREFIX_WEEK_VIEW,
} from '../utils/constantes'

const defaultUserListByPrefix = {
  [ROOM_PREFIX_WEEK_EDIT]: {},
  [ROOM_PREFIX_WEEK_VIEW]: {},

  [ROOM_PREFIX_DAY_EDIT]: {},
  [ROOM_PREFIX_DAY_VIEW]: {},
}

export const useSocketStore = create(
  immer((set) => ({
    socket: null,
    setSocket: (nextSocket) => set({ socket: nextSocket }),
    noNeedLogin: false,
    setNoNeedLogin: (nextNoNeedLogin) => set({ noNeedLogin: nextNoNeedLogin }),
    connectedRoom: null,
    setConnectedRoom: (nextConnectedRoom) => set({ connectedRoom: nextConnectedRoom }),
    userListByPrefix: defaultUserListByPrefix,
    updateUserList: (roomPrefix, date, newList) =>
      set((state) => {
        state.userListByPrefix[roomPrefix][date] = newList
      }),
    emptyAllUserLists: () => set({ userListByPrefix: defaultUserListByPrefix }),
    initAllUserLists: (newAllUserList) =>
      set((state) => {
        state.userListByPrefix[ROOM_PREFIX_WEEK_EDIT] = newAllUserList[ROOM_PREFIX_WEEK_EDIT]
        state.userListByPrefix[ROOM_PREFIX_WEEK_VIEW] = newAllUserList[ROOM_PREFIX_WEEK_VIEW]
        state.userListByPrefix[ROOM_PREFIX_DAY_EDIT] = newAllUserList[ROOM_PREFIX_DAY_EDIT]
        state.userListByPrefix[ROOM_PREFIX_DAY_VIEW] = newAllUserList[ROOM_PREFIX_DAY_VIEW]
      }),
  }))
)
