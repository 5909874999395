import { useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useAuthentificationStore } from './contexts/AuthenticationStore'
import { useShallow } from 'zustand/react/shallow'
import { useDayStore } from './contexts/DayStore'
import { useSocketStore } from './contexts/SocketStore'
import { useWeekStore } from './contexts/WeekStore'
import { SOCKET_URL, isProduction } from './utils/constantes'
import { io } from 'socket.io-client'
import { getAccessToken } from './services/TokenService'
import { useChatIndispoStore } from './contexts/ChatIndispoStore'

let intervalId = null

const SocketInitiator = () => {
  const { user } = useAuthentificationStore(useShallow((state) => ({ user: state.user })))
  const {
    socket,
    setSocket,
    updateUserList,
    initAllUserLists,
    emptyAllUserLists,
    connectedRoom,
    noNeedLogin,
  } = useSocketStore(
    useShallow((state) => ({
      noNeedLogin: state.noNeedLogin,
      updateUserList: state.updateUserList,
      initAllUserLists: state.initAllUserLists,
      setSocket: state.setSocket,
      socket: state.socket,
      connectedRoom: state.connectedRoom,
      emptyAllUserLists: state.emptyAllUserLists,
    }))
  )
  const {
    refreshSession: refreshSessionDayStore,
    refreshGroup,
    fetchDay,
  } = useDayStore(
    useShallow((state) => ({
      refreshSession: state.refreshSession,
      refreshGroup: state.refreshGroup,
      fetchDay: state.fetchDay,
    }))
  )
  const {
    refreshSession: refreshSessionWeekStore,
    fetchWeekForMG,
    fetchWeek,
  } = useWeekStore(
    useShallow((state) => ({
      refreshSession: state.refreshSession,
      fetchWeekForMG: state.fetchWeekForMG,
      fetchWeek: state.fetchWeek,
    }))
  )
  const { updateChatIndispo } = useChatIndispoStore(
    useShallow((state) => ({
      updateChatIndispo: state.updateChatIndispo,
    }))
  )

  const updateMultipleChatIndispo = useCallback(
    (multipleChatsIndispo) => {
      if (multipleChatsIndispo && multipleChatsIndispo?.length) {
        multipleChatsIndispo.forEach((nextChatIndispo) => {
          updateChatIndispo(nextChatIndispo._id, nextChatIndispo)
        })
      }
    },
    [updateChatIndispo]
  )

  // Socket initialisation when user is connected (user?._id)
  useEffect(() => {
    if ((user?._id || noNeedLogin) && !socket) {
      // Create the socket
      console.log('Creating socket...', user?._id, noNeedLogin)
      const socketInit = io(SOCKET_URL, {
        secure: isProduction,
        query: {
          noNeedLogin: noNeedLogin,
          userId: user?._id,
          token: getAccessToken(),
        },
      })
      console.log('SOCKET INIT')

      socketInit.on('disconnect', (reason) => {
        console.log('Socket disconnected : ' + reason)
        emptyAllUserLists()
      })

      socketInit.on('initUserLists', initAllUserLists)

      // Event when the connected users are updated
      socketInit.on('updateUserLists', (roomPrefix, date, newList) => {
        updateUserList(roomPrefix, date, newList)
      })

      // Force the data refresh with polling !
      socketInit.on('dayView.refreshAllDay', async () => {
        console.log('Refreshing current day...')
        toast
          .promise(
            fetchDay(),
            {
              pending: 'Loading...',
              success: 'Journée mise à jour',
              error: 'Echec de la mise à jour de la journée, veuillez actualiser',
            },
            { toastId: 'dayView.refreshAllDay' }
          )
          .catch((e) => console.error(e))
      })
      // Force the data refresh with polling !
      socketInit.on('weekView.refreshAllWeek', async () => {
        console.log('Refreshing current week...')
        toast
          .promise(
            fetchWeekForMG(),
            {
              pending: 'Loading...',
              success: 'Semaine mise à jour',
              error: 'Echec de la mise à jour de la semaine, veuillez actualiser',
            },
            { toastId: 'weekView.refreshAllWeek' }
          )
          .catch((e) => console.error(e))
      })
      socketInit.on('weekEdit.refreshAllWeek', async (preventRefreshForId) => {
        // Prevent refresh for caller
        if (preventRefreshForId === user?._id) return

        console.log('Refreshing current week...')
        toast
          .promise(
            fetchWeek(),
            {
              pending: 'Loading...',
              success: 'Semaine mise à jour',
              error: 'Echec de la mise à jour de la semaine, veuillez actualiser',
            },
            { toastId: 'weekEdit.refreshAllWeek' }
          )
          .catch((e) => console.error(e))
      })
      socketInit.on('dayView.refreshGroup', refreshGroup)
      socketInit.on('dayView.refreshSession', refreshSessionDayStore)
      socketInit.on('generator.refreshSession', refreshSessionWeekStore)

      socketInit.on('chatIndispo.updateOne', updateChatIndispo)
      socketInit.on('chatIndispo.updateMultiple', updateMultipleChatIndispo)

      // Store the socket
      setSocket(socketInit)
    }
  }, [
    setSocket,
    updateUserList,
    emptyAllUserLists,
    user?._id,
    fetchWeekForMG,
    socket,
    refreshSessionDayStore,
    refreshSessionWeekStore,
    refreshGroup,
    fetchDay,
    connectedRoom,
    initAllUserLists,
    fetchWeek,
    updateChatIndispo,
    updateMultipleChatIndispo,
    noNeedLogin,
  ])

  const rejoinMyRoom = useCallback(() => {
    if (connectedRoom) {
      console.log('[rejoinMyRoom] connectedRoom :', connectedRoom)
      socket.emit('reJoinRoom', connectedRoom)
    }
  }, [connectedRoom, socket])

  // Toutes les 3 secondes test si la socket est bien connectée
  // Re-bind l'event connect pour avoir le connectedRoom à jour
  useEffect(() => {
    if (socket) {
      clearInterval(intervalId)
      intervalId = setInterval(() => {
        // try to reconnect socket
        socket.connect()
      }, 1000 * 3)

      socket.on('connect', rejoinMyRoom)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [socket, connectedRoom, rejoinMyRoom])
}
export default SocketInitiator
